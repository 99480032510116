<template>
  <dialog
    id="global_error_modal"
    class="tw-modal tw-backdrop-blur-sm tw-bg-base-200/30"
  >
    <div class="tw-modal-box tw-px-5 tw-rounded-[40px]">
      <section
        class="tw-flex tw-flex-col tw-items-center tw-text-center tw-gap-3"
      >
        <h1 class="tw-text-xl tw-leading-tight tw-font-semibold">
          {{ info?.title }}
        </h1>
        <p>
          {{ info?.content }}
        </p>
      </section>
      <div
        class="tw-modal-action tw-justify-center tw-gap-4"
        v-if="btns?.length"
      >
        <template v-for="(btn, i) in btns">
          <UIButton
            class="tw-btn-md tw-rounded-full tw-w-[140px]"
            :class="[
              btn.type
                ? `tw-btn-${btn.type}`
                : i
                ? 'tw-btn-error'
                : 'tw-btn-secondary',
            ]"
            :loading="btn.loading"
            @click="closeDialog(btn)"
          >
            {{ btn.text }}
          </UIButton>
        </template>
      </div>
      <div class="tw-modal-action tw-justify-center tw-gap-4" v-else>
        <form method="dialog">
          <button
            class="tw-btn tw-btn-md tw-rounded-full tw-btn-primary tw-w-[140px]"
          >
            Got it
          </button>
        </form>
      </div>
    </div>
  </dialog>
</template>
<script setup lang="ts">
const modal = useModal();
const info = modal.getInfo;
const btns = ref<
  Array<{
    loading: boolean;
    text: string;
    type?: string | undefined;
    onClick?: (() => void) | undefined;
  }>
>();
watchEffect(() => {
  btns.value = info.value?.btns?.map((item) => ({ ...item, loading: false }));
});
const closeDialog = (item: {
  loading: boolean;
  text: string;
  type?: string | undefined;
  onClick?: (() => void | Promise<void>) | undefined;
}) => {
  const p = item?.onClick?.();
  if (p && p instanceof Promise) {
    item.loading = true;
    p.finally(() => {
      item.loading = false;
      modal.hide();
    });
  } else {
    modal.hide();
  }
};
</script>
